<template>
    <div class="page">
        <br />
        <div class="container-fluid">
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Relação de alunos presentes</h1>
            </div>

            <v-row>
                <v-col cols="12">
                    <v-autocomplete
                        v-model="IdUnidade"
                        :items="unidadesList"
                        item-text="Unidade"
                        item-value="IdUnidade"
                        label="Filtrar pela unidade"
                        @input="getItensTable"
                        hide-details
                        :disabled="isDisabledAdmin"
                        outlined
                        dense
                    ></v-autocomplete>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="search"
                        prepend-icon="mdi-feature-search-outline"
                        label="Pesquisar"
                        clearable
                    ></v-text-field> 
                </v-col>
            </v-row>
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
            </div>
            <v-data-table
                :headers="[
                    {text:'Turma', value:'turma', width:'40%'},
                    {text:'Turno', value:'Turno', width:'30%'},
                    {text:'Quantidade de Alunos Presentes', value:'QuantidadeAlunos', width:'30%'},
                ]"
                :items="itemsTable"
                :items-per-page="10"
                :search="search"
                class="elevation-1"
                :loading="loading"
                loading-text="Carregando...">
            </v-data-table>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            itemsTable:[],
            loading: false,
            search: '',
            unidadesList: [],
            ano: new Date().getFullYear(),
            IdUnidade: 1,
        }
    },
    computed: {
        isDisabledAdmin() {
            return this.acesso
        }
    },
    methods: {
        getItensTable(){
            const params = new URLSearchParams();
            params.append('idUnidade', this.IdUnidade);
            params.append('ano', this.ano);
            this.loading = true
            this.axios.get('/direcao', { params: params }).then((res) => {
                this.itemsTable = res.data.dados.map((value) => {
                    const turma = `${value.Serie} - ${value.TurmaDescricao}`
                    return {...value, turma}

                })
            }).finally(() => {
                this.loading = false
            })
        },
        getUnidades(){
            this.axios.get('escola/user/escolas').then(res => {
                this.unidadesList = res.data.filter(unidade => {
                    unidade.Unidade = this.prepareNameFilter(unidade.Unidade);
                    if(unidade.IdUnidadeTipo === 1) {
                        return unidade;
                    }
                })
            }).finally(() => this.getItensTable())
        },
    },
    mounted() {
        this.getUnidades()
    }
}
</script>
