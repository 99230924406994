import EducacaoDashboard from '../views/educacao/EducacaoDashboard.vue'
import EducacaoAvaliacao from '../views/educacao/EducacaoAvaliacao.vue'
import EducacaoInscrito from '../views/educacao/EducacaoInscrito.vue'
import EducacaoMonitor from '../views/educacao/EducacaoMonitor.vue'
import EducacaoCalendarioAcademico from '../views/educacao/EducacaoCalendarioAcademico'
import ContraCheque from '../views/educacao/ContraCheque'
import EducacaoDiretorVice from '../views/educacao/EducacaoDiretorVice'
import EducacaoAlunoPresente from '../views/educacao/EducacaoAlunoPresente'
import Educacenso from '../views/educacao/Educacenso'
import { permissionChecker } from '../utils/permission';

export default [ 
    {
        path: '/educacao/dashboard',
        name: 'educacaodashboard',
        component: EducacaoDashboard,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_EDUCACAO|SECRETARIA')
        }
    }, 
    
    {
        path: '/educacao/avaliacao',
        name: 'educacaoavaliacao',
        component: EducacaoAvaliacao,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_EDUCACAO|AVALIACOES')
        }
    }, 
    
    {
        path: '/educacao/inscritos',
        name: 'educacaoinscrito',
        component: EducacaoInscrito,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_EDUCACAO|SECRETARIA')
        }
    },
    
    {
        path: '/educacao/contracheque',
        name: 'Contra Cheque',
        component: ContraCheque,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_EDUCACAO|SECRETARIA')
        }
    },

    {
        path: '/educacao/diretorvice',
        name: 'Relação diretor-vice',
        component: EducacaoDiretorVice,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_EDUCACAO|RELACAO_DIRETORES')
        }
    },
    {
        path: '/educacao/relacao-alunos',
        name: 'Relação de alunos presentes',
        component: EducacaoAlunoPresente,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_EDUCACAO|RELACAO_ALUNOS')
        }
    },
    {
        path: '/educacao/Educacenso',
        name: 'Educacenso',
        component: Educacenso,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_EDUCACAO|RELACAO_DIRETORES')
        }
    },    
    {
        path: '/educacao/monitor',
        name: 'Monitor de Educação',
        component: EducacaoMonitor,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_EDUCACAO|PAINEL_EDUCACIONAL')
        }
    },
    {
        path: '/educacao/calendario',
        name: 'Calendário Academico Bimestral',
        component: EducacaoCalendarioAcademico,
        meta: {
            requiresAuth: true,
            checker: () => permissionChecker.hasAll('PORTAL_EDUCACAO|SECRETARIA')
        }
    },
];
